@import url("https://fonts.googleapis.com/css?family=Lato&display=swap");
body {
  font-size: 15px;
  color: #000000;
  font-family: "Lato", sans-serif; }

h1,
h2,
h3,
button,
.btn {
  font-family: "Lato", sans-serif; }

.title {
  text-transform: capitalize;
  margin: 0;
  font-size: 40px;
  margin-bottom: 0; }

form {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  padding: 10px;
  position: relative; }
  form label {
    width: 100%;
    display: flex;
    flex-direction: column; }
    form label input {
      margin-top: 10px;
      flex: 1; }

button {
  margin: 0;
  padding: 10px;
  border: 0px;
  cursor: pointer;
  padding: 0;
  font-size: 15px;
  line-height: 15px !important; }

.btn {
  text-align: center;
  font-size: 16px !important;
  line-height: 16px !important; }

.btn {
  padding: 18px 32px;
  border-radius: 4px;
  background: #000;
  color: #fff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 1px;
  cursor: pointer !important;
  outline: none;
  border: 1px solid transparent;
  font-weight: bold;
  text-transform: lowercase;
  transition: opacity 0.5s ease;
  opacity: 1; }
  .btn-secondary {
    background: #4bbcbc; }
  .btn-transparent {
    background: transparent;
    color: #3b2774; }
  .btn-primary {
    background: #3b2774;
    border: 1px solid transparent; }
  .btn-gray {
    background: #ebe7f1;
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: #000; }
  .btn i {
    padding-left: 10px; }
  .btn:hover {
    opacity: 0.8; }
  .btn:focus {
    outline: none; }

.buttons {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end; }
  .buttons .btn {
    margin-right: 10px; }
    .buttons .btn:last-child {
      margin: 0; }

body {
  margin: 0;
  padding: 0; }

.panel {
  padding: 20px;
  display: block;
  max-width: 100%;
  background: #fff;
  margin-bottom: 20px; }
  .panel__head {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .panel__head .title {
      margin: 0 !important; }

@media (min-width: 600px) {
  .full {
    grid-column: 1 / span 2; } }

.inline {
  display: flex;
  justify-content: center;
  align-items: center; }

.info {
  padding: 5px; }
  .info b {
    display: inline-block;
    width: 120px;
    border-right: 1px solid #ebe7f1;
    margin-right: 10px; }

.modal-panel {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: none;
  justify-content: center;
  align-items: center;
  bottom: 0;
  z-index: 9;
  display: none;
  background: rgba(0, 0, 0, 0.1); }

.open-modal {
  display: flex !important; }

.row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px; }
  @media (max-width: 1090px) {
    .row {
      grid-template-columns: repeat(1, 1fr); } }

.row-1 {
  display: grid;
  grid-gap: 30px;
  margin-top: 30px; }

.span-3 {
  grid-column: 1 / span 3; }

.span-2,
.span-1-2 {
  grid-column: 1 / span 2; }

.span-2-3 {
  grid-column: 2 / span 3; }

.row-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px; }
  @media (max-width: 1090px) {
    .row-3 {
      grid-template-columns: repeat(1, 1fr); } }

.row-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px; }
  @media (max-width: 1090px) {
    .row-4 {
      grid-template-columns: repeat(1, 1fr); } }

.media {
  display: flex; }
  .media__item {
    padding: 10px;
    border-radius: 8px;
    height: 120px;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.1);
    color: #3b2774;
    text-decoration: none; }
    .media__item:hover {
      text-decoration: none; }

.card-map {
  grid-row: 1 / span 2;
  grid-column: 3;
  padding: 0px !important; }

.info {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0; }
  .info p {
    margin: 0;
    padding: 10px; }
  .info .label {
    text-transform: uppercase;
    display: inline-block;
    min-width: 130px;
    background: rgba(0, 0, 0, 0.02);
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    margin-right: 10px; }

.car-preview img {
  width: 100%; }

.view__intro {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }

.login__view {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  width: 100%; }
  .login__view h1 {
    text-transform: uppercase;
    color: #3b2774;
    padding-bottom: 20px; }
  .login__view .form {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.04);
    display: flex;
    width: 90%;
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
    justify-content: center;
    align-items: center; }
    .login__view .form img {
      max-width: 180px;
      margin: 30px; }

.dashboard .description {
  opacity: 0.6;
  margin: 0; }

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }
  .loading i {
    color: #3b2774;
    font-size: 18px;
    margin: 10px; }
  .loading img {
    width: 180px;
    display: block;
    margin: 0 auto;
    transform: scale(1); }

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0.3; }
  50% {
    transform: scale(1.2);
    opacity: 1; }
  100% {
    transform: scale(1);
    opacity: 0.3; } }

.loading {
  position: relative; }
  .loading__view {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 88;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center; }
    .loading__view i {
      color: #000;
      font-size: 30px; }

.card {
  border: 1px solid #ebe7f1;
  background: #fff;
  padding: 20px;
  min-height: 200px;
  display: flex;
  border-radius: 6px;
  flex-direction: column; }
  .card__title {
    text-align: center;
    color: #3b2774;
    margin-top: 0;
    margin-bottom: 20px; }
  .card h1 {
    margin: 0;
    color: #3b2774; }
  .card h3 {
    padding-top: 10px;
    margin: 0;
    opacity: 0.7;
    color: #3b2774;
    text-transform: lowercase; }
  .card .buttons {
    flex: 1; }
    .card .buttons a:first-child {
      margin-right: 30px; }
    .card .buttons i {
      font-size: 24px; }

.table {
  border: 1px solid #ebe7f1; }
  .table__head {
    display: flex;
    background: #ebe7f1; }
  .table__item {
    flex: 1;
    padding: 15px;
    border-right: 1px solid #ebe7f1; }
    .table__item:last-child {
      border-color: transparent; }
    .table__item a {
      color: #3b2774; }
  .table .loading {
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center; }
    .table .loading i {
      font-size: 32px;
      color: #ebe7f1; }
  .table__row {
    display: flex; }
  .table__empty {
    padding: 4em;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    opacity: 0.2; }
    .table__empty i {
      font-size: 2em; }
    .table__empty p {
      margin: 10px 0 0 0; }

.powered {
  max-width: 130px;
  display: block;
  padding: 20px 0; }
  .powered img {
    width: 100%;
    display: block;
    margin: 0 auto; }
  .powered .contact {
    display: flex;
    flex-direction: column;
    align-items: flex-end; }
    .powered .contact__item {
      display: flex;
      align-items: center;
      opacity: 0.6;
      padding-bottom: 2px;
      font-size: 10px; }
      .powered .contact__item .icon {
        width: 15px; }
      .powered .contact__item span {
        text-align: right;
        flex: 1; }

.calculator {
  display: flex;
  background: #ebe7f1;
  border-radius: 6px; }
  @media (max-width: 599px) {
    .calculator {
      flex-direction: column; } }
  .calculator .disabled {
    opacity: 0.4; }
  .calculator__box,
  .calculator .checkbox-card {
    position: relative;
    flex: 1; }
    .calculator__box .checkbox,
    .calculator .checkbox-card .checkbox {
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 22; }
  .calculator__box__gray {
    background: transparent !important;
    border-radius: 0 !important;
    margin: 0 -6px;
    z-index: 0 !important; }
    .calculator__box__gray h1 {
      text-align: center; }
    .calculator__box__gray a::after {
      display: none !important; }
  .calculator__box {
    z-index: 10;
    min-height: 340px;
    min-height: calc(100% - 40px);
    background: #fff;
    padding: 20px;
    border: 1px solid #ebe7f1;
    border-radius: 6px;
    flex: 1; }
    .calculator__box h1 {
      font-size: 24px;
      font-weight: bold; }
    .calculator__box form {
      max-width: 300px;
      display: block;
      margin: 20px auto; }
      .calculator__box form .cost {
        text-align: center; }
        .calculator__box form .cost b {
          font-size: 24px;
          font-weight: bold; }
      .calculator__box form .input-row {
        margin-bottom: 10px;
        display: grid;
        grid-template-columns: 100px 1fr; }
        .calculator__box form .input-row span {
          line-height: 45px;
          vertical-align: middle; }
        .calculator__box form .input-row-wrapper {
          background: #fff;
          width: 100%;
          display: flex;
          align-items: center; }
          .calculator__box form .input-row-wrapper .badge {
            width: 45px;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center; }
          .calculator__box form .input-row-wrapper input {
            background: #fff !important;
            margin: 0 !important;
            padding: 15px 0 !important; }
    .calculator__box ul {
      padding: 0; }
      .calculator__box ul li {
        padding: 10px 10px; }
        .calculator__box ul li::before {
          color: currentColor; }
    .calculator__box a {
      color: currentColor;
      text-decoration: none; }
      .calculator__box a::after {
        font-family: "Font Awesome 5 Pro";
        font-weight: 100;
        content: "\f105";
        display: inline-block;
        padding-left: 5px;
        color: currentColor; }
    .calculator__box .ups__item {
      display: flex;
      align-items: center;
      padding: 5px; }
      .calculator__box .ups__item .icon {
        margin-right: 20px; }
        .calculator__box .ups__item .icon i {
          font-size: 24px;
          color: #4bbcbc; }
    .calculator__box .cta {
      margin-top: 30px; }
      .calculator__box .cta a {
        text-decoration: none;
        color: #3b2774; }

@media print {
  .side {
    display: none !important; }
  .layout__head {
    display: none !important; }
  .layout__body {
    margin: 0 !important; } }

.wrapper {
  width: 100%; }
  @media (min-width: 600px) {
    .wrapper {
      display: flex; } }

.side {
  min-width: 260px;
  height: 100vh;
  background: #ebe7f1;
  display: flex;
  flex: 1;
  flex-direction: column;
  position: fixed; }
  .side .is-active {
    background: #fff !important;
    border-left: 0 !important;
    opacity: 1 !important; }
  .side .sign-out {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    flex-direction: column; }
  .side a {
    padding: 30px;
    display: flex;
    align-items: center;
    flex-direction: row;
    color: #3b2774;
    opacity: 0.5;
    text-decoration: none;
    text-transform: uppercase;
    transition: 0.5s linear opacity; }
    .side a:hover {
      opacity: 1; }
    .side a i {
      font-size: 24px;
      min-width: 50px; }
    .side a span {
      font-size: 14px; }
    .side a:hover {
      color: #3b2774;
      text-decoration: none; }
    .side a b {
      opacity: 0.3;
      padding-right: 20px;
      padding-bottom: 10px; }
  .side .is-active {
    border-left: 4px solid #000; }

.layout {
  width: 100%;
  display: block; }
  .layout .shadow {
    background: transparent;
    transition: all 0.5s ease;
    opacity: 0; }
  .layout__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 30px; }
    .layout__head input {
      margin: 0; }
  .layout__body {
    margin-left: 260px;
    display: block;
    padding: 30px;
    min-height: calc(100vh - 60px);
    background: #fff; }
    .layout__body .logo {
      position: fixed;
      right: 30px;
      bottom: 0px;
      width: 200px; }
      .layout__body .logo a {
        width: 100%;
        max-width: 100% !important;
        text-decoration: none !important; }
      .layout__body .logo img {
        opacity: 0.4; }
      .layout__body .logo .contact__item {
        font-size: 16px !important; }
        .layout__body .logo .contact__item span {
          margin-left: 10px; }

.panel__loading {
  min-height: calc(100vh - 60px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  flex-direction: column; }
  .panel__loading .description {
    opacity: 0.3;
    font-size: 12px;
    text-transform: lowercase;
    margin: 0; }

.file-upload {
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin: 10px 0;
  cursor: pointer;
  background: #f7f7f7; }

.media-item {
  background: #f7f7f7;
  width: 100%;
  max-width: 300px;
  position: relative; }
  .media-item i {
    position: absolute;
    top: 10px;
    right: 10px;
    background: #fff;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    line-height: 30px;
    vertical-align: middle;
    text-align: center;
    cursor: pointer; }
  .media-item img {
    width: 100%; }

.media-items {
  display: grid;
  grid-template-columns: repeat(3, 1fr); }
  .media-items .empty {
    opacity: 0.6; }

.search input {
  padding: 10px; }

.nav-wrapper-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  cursor: pointer;
  background: #fff;
  transition: 500ms ease background; }
  .nav-wrapper-head:hover {
    background: rgba(0, 0, 0, 0.1); }
  .nav-wrapper-head span {
    text-transform: uppercase; }

.nav-wrapper-items {
  overflow: hidden;
  height: 0px;
  opacity: 0;
  transition: 200ms ease opacity; }

.nav-wrapper .open {
  opacity: 1;
  height: 100%; }

.pagination {
  display: flex;
  margin: 20px 0;
  justify-content: flex-end; }
  .pagination .total {
    display: none; }
  .pagination .arrows {
    display: flex;
    justify-content: center;
    align-items: center; }
    .pagination .arrows .current {
      width: 80px;
      text-align: center; }
    .pagination .arrows .arrow {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      background: #ebe7f1; }
      .pagination .arrows .arrow i {
        font-size: 32px;
        color: #3b2774; }

.checkbox {
  display: flex;
  align-items: center; }
  .checkbox .box {
    width: 25px;
    height: 25px;
    background: transparent;
    border: 2px solid #3b2774;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 500ms ease background;
    border-radius: 4px;
    margin-right: 10px; }
  .checkbox .filled {
    background: #3b2774;
    color: #fff; }
    .checkbox .filled::after {
      font-family: "Font Awesome 5 Pro";
      font-weight: 100;
      content: "\f00c";
      display: inline-block; }

input[type="file"] {
  border: 0px !important; }

input,
textarea {
  padding: 15px 10px;
  outline: none;
  display: flex;
  align-self: stretch;
  border: 1px solid transparent;
  margin-bottom: 20px;
  background: #ebe7f1;
  transition: background 0.5s ease; }
  input::placeholder,
  textarea::placeholder {
    color: #3b2774;
    opacity: 0.4; }

.input-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%; }
  .input-wrapper .label {
    margin: 0;
    padding-bottom: 5px;
    color: #3b2774;
    opacity: 0.2;
    transition: 500ms ease opacity; }
  .input-wrapper input,
  .input-wrapper textarea {
    flex: 1; }

.focussed .label {
  opacity: 0.5 !important; }

.options {
  width: 145px;
  position: relative; }
  .options__head {
    display: flex;
    justify-content: space-between;
    padding: 0px 10px;
    align-items: center;
    cursor: pointer;
    background: #fff;
    transition: 0.5s ease background; }
    .options__head:hover {
      background: rgba(0, 0, 0, 0.05); }
  .options .open {
    overflow: scroll;
    width: 300px;
    max-height: 150px; }
  .options__body {
    position: absolute;
    overflow: hidden;
    max-height: 0px;
    top: 48px;
    right: 0;
    transition: 0.2s linear max-height;
    background: #fff;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    z-index: 11; }
    .options__body .option {
      text-align: center;
      cursor: pointer;
      background: #fff;
      transition: 0.5s ease background;
      height: 50px;
      width: 150px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05); }
      .options__body .option:hover {
        background: rgba(0, 0, 0, 0.05); }
      .options__body .option:last-child {
        border-color: transparent; }
      .options__body .option p {
        width: 100%;
        text-align: center;
        margin: 0; }

.step {
  display: flex;
  position: relative; }
  .step__content {
    padding: 20px;
    padding-left: 60px; }
  .step .badge {
    position: absolute;
    width: 50px;
    height: 50px;
    background: #3b2774;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center; }
    .step .badge span {
      color: #fff; }

.editor {
  border: 1px solid rgba(0, 0, 0, 0.1); }
  .editor .demo-editor {
    padding: 10px; }

.public-DraftEditor-content ul,
.public-DraftEditor-content ol,
.calculator ul,
.calculator ol {
  list-style: none; }
  .public-DraftEditor-content ul li,
  .public-DraftEditor-content ol li,
  .calculator ul li,
  .calculator ol li {
    list-style: none;
    display: flex;
    align-items: center; }
    .public-DraftEditor-content ul li:before,
    .public-DraftEditor-content ol li:before,
    .calculator ul li:before,
    .calculator ol li:before {
      font-family: "Font Awesome 5 Pro";
      margin-right: 10px;
      content: "\f00c"; }

.draggable__list__item {
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  transition: background 500ms ease; }
  .draggable__list__item p {
    margin: 0;
    vertical-align: middle; }
  .draggable__list__item .icon {
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center; }

.is-dragging {
  background: rgba(0, 0, 0, 0.1);
  opacity: 0.1; }

.hover-helper {
  background: rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; }

.disable-children {
  pointer-events: none; }

.drag-area {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.1);
  z-index: 999; }

.dropzone {
  position: relative;
  min-height: 200px;
  width: 100%;
  display: block;
  border: 1px solid white; }

.options {
  margin-bottom: 20px; }
  .options-label {
    margin: 0;
    padding-bottom: 5px;
    color: #3b2774;
    opacity: 0.2;
    transition: 500ms ease opacity; }
  .options-body {
    display: flex; }
    .options-body .option {
      padding: 12px 24px;
      background: #fff;
      border: 1px solid #ebe7f1;
      cursor: pointer;
      margin-right: 4px; }
      .options-body .option-active {
        background: #3b2774;
        color: #fff; }
